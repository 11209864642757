import React from 'react';
import './App.css';
import './css/main.css'
import Nav from './components/navBar';
import Router from './Router';
import Footer from './components/footer';
import LoadingScrn from './components/loadingScrn'
import { BrowserRouter } from "react-router-dom";

function App() {
  const contentLoaded = () => {
    return true;
  }

  if (contentLoaded()){
    return (
      <div className="App">
        <BrowserRouter>
          <Nav />
          <Router />
          <Footer />
        </BrowserRouter>
      </div>
    );
  }else {
    return (
      <div className="LoadingScrn">
        <LoadingScrn />
      </div>
    )
  }
}

export default App;
