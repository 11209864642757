import * as React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "https://codigoaustin.us3.list-manage.com/subscribe/post?u=e22038f3ba0d3e82e4c516161&amp;id=4b89e5ce31";
// a basic form
interface ICustomFormProps {
  status: string|null,
  message: any, 
  onValidated: any
}
const CustomForm: React.FunctionComponent<ICustomFormProps> = (props) => {
  let email:HTMLInputElement|null, name:HTMLInputElement|null;
  const submit = () =>
    email &&
    name &&
    email.value.indexOf("@") > -1 &&
    props.onValidated({
      EMAIL: email.value,
      NAME: name.value
    });

  return (
    <div id="CustomFormContainer">
      {props.status === "sending" && <div className="alert">sending...</div>}
      {props.status === "error" && (
        <div
          className="alert"
          dangerouslySetInnerHTML={{ __html: props.message }}
        />
      )}
      {props.status === "success" && (
        <div
          className="alert"
          dangerouslySetInnerHTML={{ __html: props.message }}
        />
      )}
      <input
        ref={node => (name = node)}
        type="text"
        placeholder="Name"
        id="mce-EMAIL"
      />
      <br />
      <input
        ref={node => (email = node)}
        type="email"
        placeholder="Email"
        id="mce-EMAIL"
      />
      <br />
      <button className="button" onClick={submit} id="subBttn">
        SUBMIT
      </button>
    </div>
  );
};
interface ISubscribeProps {
}

const Subscribe: React.FunctionComponent<ISubscribeProps> = (props) => {
  return (
    <div className="subscribe">
      <label>Subscribe</label>
      <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData:any) => subscribe(formData)}
            />
          )}
        />
    </div>
  );
};

export default Subscribe;
