import * as React from 'react';
import {Doughnut} from 'react-chartjs-2';


interface IDataChartProps {
}

const DataChart: React.FunctionComponent<IDataChartProps> = (props) => {
  
  function ChartComp () {
    let chartData: object = {
            labels: ['White', 'Black', 'Hispanic', 'Asian American', 'Am. Indian', 'Hawaiian', 'TOMR', 'Women'],
            datasets: [{
                label: 'People In High Tech',
                data: [68.5, 7.4, 8, 14, 0.4, 0.3, 1.3, 35.7],
                backgroundColor: [
                    'rgba(189, 63, 52, 1)',
                    'rgba(245, 93, 62, 1)',
                    'rgba(247, 203, 21, 1)',
                    'rgba(86, 91, 107, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(178, 103, 94, 1)',
                    'rgba(37, 40, 49, 1)'
                ],
                borderColor: [
                    'rgba(189, 63, 52, 1)',
                    'rgba(245, 93, 62, 1)',
                    'rgba(247, 203, 21, 1)',
                    'rgba(86, 91, 107, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(178, 103, 94, 1)',
                    'rgba(37, 40, 49, 1)'
                ],
                borderWidth: 1
            }]
        }
    return (
      <div className="chart">
        <h3>People In High Tech</h3>
        <Doughnut data={chartData} height={40/window.innerWidth}/>
        <p><a href="https://www.eeoc.gov/eeoc/statistics/reports/hightech/">Data provided by the Equal Employment Opportunity Commission (2014)</a></p>
      </div>
    );
  }
  return (
    <div className="content-1" id="tester">
      <div className="stats">
        <div className="quote">
          <h2>"A Study Finds That Diverse Companies Produce 19% More Revenue."</h2>
          <p><a href="https://www.forbes.com/sites/annapowers/2018/06/27/a-study-finds-that-diverse-companies-produce-19-more-revenue/#e97175a506f3">Anna Powers, Forbes</a></p>
        </div>
        <ChartComp />
      </div>
      <div className="back-text1">Co.</div>
    </div>
  );
};

export default DataChart;
