import { Switch, Route, Redirect } from "react-router";
import Landing from './components/landing';
import * as React from 'react';

interface IRouterProps {
}

const Router: React.FunctionComponent<IRouterProps> = (props) => {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
    </Switch>
  );
};

export default Router;