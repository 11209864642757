import * as React from 'react';

interface IHeaderProps {
}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const diversities: Array<string> = ["01100011 01101111 01100100 01101001 01100111 01101111 ", "01100001 01110101 01110011 01110100 01101001 01101110 ","diversity.LatinosIn()>", "diversity.WomenIn()>", "diversity.ColorIn()>","diversity.LGBTQ+()>","636f6469676f", "61757374696e", "object->lgbtq+()", "<Women in />", "<lgbtq+ in />", "<Color in />", "<Latinos in />", "<Women in />", "<lgbtq+ in />", "<Color in />", "<Latinos in />", "<Women in />", "<lgbtq+ in />", "<Color in />", "<Diversify />"];
  const [diversity, setDiversity] = React.useState<string>("");
  let iterateDiversities = (counter:number) => {
    if(counter < diversities.length){
      setDiversity(diversities[counter]);
      setTimeout(() => iterateDiversities(counter+1), 120)
    }
  }
  React.useEffect(() => {
    let counter:number = 0;
    iterateDiversities(counter);
  }, [])
  return(
    <div className="header">
      <div className="background">
      </div>
        <p className="diversity">{diversity}</p>
        <h1>STEM</h1>
      <h2 className="mission">
        Código Austin is a 501(c)3 non-profit organization that promotes science, technology, engineering, arts, & math (STEAM) fields to 
        Austin's underserved youth through involvement,
        exposure, & mentorship.
      </h2>
      <div className="arrow bounce">
        <a className="fa fa-arrow-down fa-2x"><span/></a>
      </div>
    </div>
  );
};

export default Header;
