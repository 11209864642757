import * as React from 'react';
import logo from '../img/CALogoSM.png';
import antilogo from '../img/antilogo.png'
import { AddCircle } from "@material-ui/icons";
import classnames from "classnames";
import { type } from 'os';

interface INavBarProps {
}

const NavBar: React.FunctionComponent<INavBarProps> = (props) => {
  const [scrollTop, updateScrollHeight] = React.useState(window.scrollY);
  const [compHeight, setCompHeight] = React.useState<number | unknown>(0);
  const [navBarClass, setNavBarClass] = React.useState(["default"]);
  const [navTxtClass, setNavTxtBarClass] = React.useState(["navTxt"]);
  const [genLogoClass, setGenLogoClass] = React.useState(["ca-logo", "logo-dark"]);
  const [antiLogoClass, setAntiLogoClass] = React.useState(["ca-logo", "logo-dark"]);
  let navBar: string = classnames(navBarClass);
  let navTxt: string = classnames(navTxtClass);
  let antiLogo: string = classnames(antiLogoClass);
  let genLogo: string = classnames(genLogoClass);
  let currentScrollTop: number = 0;
  currentScrollTop = scrollTop;
  let c: number = currentScrollTop;
  React.useEffect(() => {
    navBar = classnames(navBarClass);
    navTxt = classnames(navTxtClass);
    antiLogo = classnames(antiLogoClass);
    genLogo = classnames(genLogoClass);
    const handleScroll = () => {
      updateScrollHeight(window.scrollY)
      let a: number = scrollTop;
      let b: number | unknown = compHeight;
      if(typeof compHeight === "number" && typeof b === "number"){
        if(a <= b){
          setNavBarClass(["default"]);
          setNavTxtBarClass(["navTxt"]);
          // setAntiLogoClass(["ca-logo", "logo-dark", "hide"]);
          if (antiLogoClass.includes("scrollUp"))
            setAntiLogoClass(["ca-logo", "logo-dark"]);
        }else if (a > b + b && !navBarClass.includes("scrollUp") && !genLogoClass.includes("scrollUp")) {
          setNavBarClass([...navBarClass, "scrollUp"]);
          setAntiLogoClass([...genLogoClass, "scrollUp"]);
        } 
        // else if (!(a <= b)) {
        //   console.log(`Last triggered.`);
        //   setNavBarClass([...navBarClass.splice(1, 1), "transpNav"]);
        //   setNavTxtBarClass([...navTxtClass, "transpNavTxt"]);
        //   setGenLogoClass([...genLogoClass.splice(2, 1)]);
        //   setAntiLogoClass([...antiLogoClass.splice(2, 1)])
        // }
      }
    }
    window.addEventListener('scroll', handleScroll)

    let height: unknown | React.Dispatch<React.SetStateAction<undefined>>;
    height = document.getElementById('navBar')?.clientHeight;
    setCompHeight(height);

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollTop, compHeight, navBarClass, navTxtClass, antiLogoClass, genLogoClass, navBar, navTxt, antiLogo, genLogo])
  
  
  return (
    <div className="navBar">
      <div id="burger">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {/* <img className={genLogo} src={logo} alt="Codigo Austin Logo" /> */}
      <img className={antiLogo} src={antilogo} alt="Codigo Austin Logo" />
      <nav className="mobile">
        <div id="content">
          {/* <div className="links">
            <a className="" href="http://">Home<div className="ulLeft"></div><div className="ulRight"></div></a>
            <a className="" href="http://">What We Do <div className="ulLeft"></div><div className="ulRight"></div></a>
            <a className="" href="http://">Our Story <div className="ulLeft"></div><div className="ulRight"></div></a>
            <a className="" href="http://">About<div className="ulLeft"></div><div className="ulRight"></div></a>
            <a className=" involve" href="http://"><AddCircle /> Get Involved </a>
            <a className="" href="http://">Contact <div className="ulLeft"></div><div className="ulRight"></div></a>
          </div> */}
        </div>
      </nav>
      <nav className={navBar} id="navBar">
        <div className="gap"></div>
        {/* <div className="links">
          <a className={navTxt} href="http://codigoaustin.org">Home<div className="ulLeft"></div><div className="ulRight"></div></a>
          <a className={navTxt} href="http://">What We Do <div className="ulLeft"></div><div className="ulRight"></div></a>
          <a className={navTxt} href="http://">Our Story <div className="ulLeft"></div><div className="ulRight"></div></a>
          <a className={navTxt} href="http://">About<div className="ulLeft"></div><div className="ulRight"></div></a>
          <a className={navTxt} href="http://">Get Involved</a>
          <a className={navTxt} href="http://">Contact <div className="ulLeft"></div><div className="ulRight"></div></a>
        </div> */}
      </nav>
    </div>
  );
};

export default NavBar;
