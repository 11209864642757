import * as React from 'react';
import bulb from '../../img/icon-bulb.svg';
import flag from '../../img/icon-flag.svg';
import compass from '../../img/icon-compass.svg';
import flame from '../../img/icon-flame.svg';

interface IGetInvolvedProps {
}

const GetInvolved: React.FunctionComponent<IGetInvolvedProps> = (props) => {
  return (
    <div className="content-2">
      <h2>Get Involved</h2>
      <div className="cards">
        <a href="#"><div className="card mentor">
          <img src={bulb} alt="" />
          <h2>Become a Mentor</h2>
          <p>If you are looking to be more hands on in you're involvement, consider being a mentor!
            Mentors work directly with students to help guide and empower them. Mentors are an essential
            pillar for the success of our program.
          </p>
        </div></a>
        <a href="#"><div className="card partner">
          <img src={flag} alt="" />
          <h2>Partner With Us</h2>
          <p>Whether you're a school that would like to implement Código Austin at your school or if you're
            a company that would like to work with us, please feel free to reach out! We are always
            looking to be involved with our community.
          </p>
        </div></a>
        <a href="#"><div className="card donateOnce">
          <img src={compass} alt="" />
          <h2>Bit Club</h2>
          <p>Donate once & you are automatically in our Bit Club! 
            One time donations can go towards things like new computers or other tools for students so that they can be successful in any projects they take on!
          in our program!</p>
        </div></a>
        <a href="#"><div className="card donateRecurring">
          <img src={flame} alt="" />
          <h2>Terabyte Club</h2>
          <p>Become a recurring donor to be part of our Terabyte Club! 
            From $1 a month to $1,000 a month, recurring donors keep our organization going.
            Recurring donations can do everything from providing dinner for students
            to forming scholarships for students. 
          </p>
        </div></a>
      </div>
      <div className="back-text2">Au.</div>
    </div>
  );
};

export default GetInvolved;
