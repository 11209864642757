import React from 'react';
import { Redirect } from 'react-router-dom';
import Header from './landingComps/header';
import DataChart from './landingComps/dataChart';
import GetInvolved from './landingComps/getInvolved';
import Newsletter from './landingComps/subscribe';
import Partners from './landingComps/partners'

const Landing: React.FC = () => {
  return(
    <div className="landing">
      <Header />
      <DataChart />
      <GetInvolved />
      <Partners />
      <Newsletter />
    </div>
  )
}

export default Landing;