import * as React from 'react';
import kippLogo from '../../img/KIPP.png';
import acaLogo from '../../img/austinca.png';
import silabsLogo from '../../img/silabs.png';
import dellLogo from '../../img/dell.png';

interface IPartnersProps {
}

const Partners: React.FunctionComponent<IPartnersProps> = (props) => {
  return (
    <div className="partners">
      <h1>Nuestra Familia</h1>
      <div className="icons">
        <a href="https://kippaustin.org/"><img src={kippLogo} alt="KIPP: Texas" /></a>
        <a href="https://austincodingacademy.com/"><img src={acaLogo} alt="Austin Coding Academy" /></a>
        <a href="https://www.silabs.com/"><img src={silabsLogo} alt="Silicon Labs" /></a>
        <a href="https://www.dell.com/"><img src={dellLogo} alt="Dell" id="dell" /></a>
      </div>
    </div>
  );
};

export default Partners;
