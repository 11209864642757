import * as React from 'react';
import antilogo from '../img/antilogo.png'
import { Instagram, Facebook, Twitter, LinkedIn, Code} from '@material-ui/icons';

interface IFooterProps {
}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  return (
    <div className="footer">
      <div className="links">
        <img src={antilogo} alt="Codigo Austin Logo" />
        <div className="list">
          <div className="social column">
            <h2>Follow Us</h2>
            <ul>
              <li><a href="https://www.instagram.com/codigoaustin/"><Instagram className='icon' />
                Instagram </a></li>
              <li><a href="https://www.facebook.com/CodigoAustin/"><Facebook className='icon' />
                Facebook</a></li>
              <li><a href="https://twitter.com/codigoaustin"><Twitter className='icon' />
                Twitter</a></li>
              <li><a href="https://www.linkedin.com/company/codigoaustin/"><LinkedIn className='icon' />
                LinkedIn</a></li>
            </ul>
          </div>
          <div className="get-involved column">
            <h2>Get Involved</h2>
            <ul>
              <li><a href="mailto: contact@codigoaustin.org">contact@codigoaustin.org</a></li>
              <li><a href="mailto: contact@codigoaustin.org">Become A Mentor</a></li>
              <li><a href="mailto: contact@codigoaustin.org">One Time Donation</a></li>
              <li><a href="mailto: contact@codigoaustin.org">Recurring Donor</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="base">
        <p>© CODIGO AUSTIN</p>
        <Code />
      </div>
    </div>
  );
};

export default Footer;
